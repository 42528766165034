import React from "react";
import Gist from "react-gist";

class Diseases extends React.Component {
  render(){
  return (
    <div className="container pt-5">
      <div className="container pt-5">
        <h2 >Cacao Diseases Notebook</h2>
        <Gist  id="165ed4825b8d18338bd705d01d8121ff"/>
      </div>
    </div>
    
    );
  }
};

export default Diseases;