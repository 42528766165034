import React from "react";
import LoadModel from "./LoadModel";
import TitleSection from "./TitleSection";
import DownloadSection from "./DownloadSection";
import MapSection from "./MapSection";

class Home extends React.Component {
  render() {
    return (
      <div>
        <TitleSection />
        <MapSection />
        <LoadModel />
        <DownloadSection />
      </div>
    );
  }
}

export default Home;
