import React from "react";
import google_play from "./assets/images/google-play-badge.svg";

class DownloadSection extends React.Component {
  render() {
    return (
      <section className="download gradient" id="download">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,32L18.5,42.7C36.9,53,74,75,111,85.3C147.7,96,185,96,222,122.7C258.5,149,295,203,332,213.3C369.2,224,406,192,443,154.7C480,117,517,75,554,74.7C590.8,75,628,117,665,128C701.5,139,738,117,775,90.7C812.3,64,849,32,886,58.7C923.1,85,960,171,997,192C1033.8,213,1071,171,1108,160C1144.6,149,1182,171,1218,160C1255.4,149,1292,107,1329,122.7C1366.2,139,1403,213,1422,250.7L1440,288L1440,0L1421.5,0C1403.1,0,1366,0,1329,0C1292.3,0,1255,0,1218,0C1181.5,0,1145,0,1108,0C1070.8,0,1034,0,997,0C960,0,923,0,886,0C849.2,0,812,0,775,0C738.5,0,702,0,665,0C627.7,0,591,0,554,0C516.9,0,480,0,443,0C406.2,0,369,0,332,0C295.4,0,258,0,222,0C184.6,0,148,0,111,0C73.8,0,37,0,18,0L0,0Z"
          ></path>
        </svg>
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1>Discover more about your cacaos.</h1>
              <p>
                Available on android version 5.0+ absolutely free.
                <br />
                Download now and get started!
              </p>
              <div className="badges col-md-3">
                <a
                  className="badge-link"
                  href="https://play.google.com/store/apps/details?id=sikwati.sikwati"
                  target="_blank"
                >
                  <img
                    className="img-fluid"
                    src={google_play}
                    alt="google play store logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DownloadSection;
