import React from "react";
import "regenerator-runtime/runtime";
import * as tf from "@tensorflow/tfjs";
import Dropzone from "react-dropzone";
import tensor_flow_loading from "./assets/images/tensor_flow_loading.svg";
import predicting from "./assets/images/predicting.svg";
import black_pod_rot_1 from "./assets/images/test_photos/black_pod_rot_1.jpg";
import healthy_1 from "./assets/images/test_photos/healthy_1.jpg";
import pod_borer_1 from "./assets/images/test_photos/pod_borer_1.jpg";

class LoadModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: null,
      imagePreviewUrl: null,
      model: null,
      is_loaded: null,
      files: [],
      warning: "none",
      predict: "collapse",
      button_disable: "",
    };

    this.loadModelHandler();
    this.onDrop = (files) => {
      var i;
      for (i = 0; i < files.length; i++) {
        Object.assign(files[i], {
          preview: URL.createObjectURL(files[i]),
          id: "cacao_" + i,
        });
      }
      this.setState({ files, warning: "none" });
    };
  }

  loadModelHandler = async () => {
    const { model } = this.state;
    await this.setState({
      model: await tf.loadLayersModel(
        "https://raw.githubusercontent.com/zopagaduanjr/models/master/model.json"
      ),
      is_loaded: "model successfully loaded.",
    });
  };

  multiplePredictHandler = async () => {
    const { files, model } = this.state;
    if (files.length == 0) {
      this.setState({
        warning: "none",
        predict: "pt-3",
        button_disable: "disabled",
      });
      this.setState({
        warning: "block",
        predict: "collapse",
        button_disable: "",
      });
    } else {
      this.setState({
        warning: "none",
        predict: "pt-3",
        button_disable: "disabled",
      });

      for (let index = 0; index < files.length; index++) {
        var x = document.getElementById(files[index].id);
        const tensor_image = tf.browser
          .fromPixels(x, 3)
          .resizeNearestNeighbor([224, 224])
          .expandDims();
        let prediction = await model.predict(tensor_image).data();
        let i = prediction.indexOf(Math.max(...prediction));
        const class_names = ["Black Pod Rot", "Healthy", "Pod Borer"];
        Object.assign(files[index], {
          prediction: "prediction: " + class_names[i],
          confidence: "confidence: " + prediction[i],
        });
      }
      this.setState({ files, predict: "collapse", button_disable: "" });
    }
  };

  loadPhotos = async () => {
    const { files, warning } = this.state;
    this.setState({
      warning: "none",
      predict: "pt-3",
      button_disable: "disabled",
    });
    const images = [black_pod_rot_1, healthy_1, pod_borer_1];
    for (let index = 0; index < images.length; index++) {
      const response = await fetch(images[index]);
      const blob = await response.blob();
      const file = new File([blob], images[index], { type: blob.type });
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        id: "cacao_" + index,
      });
      files.push(file);
    }
    this.setState({ files, predict: "collapse", button_disable: "" });
  };

  render() {
    const images = this.state.files.map((file) => (
      <div key={file.name} className="col-sm demo-images">
        <p style={{ color: "gray" }}>file name: {file.name}</p>
        <p>{file.prediction}</p>
        <p>
          <b>{file.confidence}</b>
        </p>
        <img
          className="img-fluid"
          id={file.id}
          src={file.preview}
          style={{ width: "200px" }}
          alt="preview"
        />
      </div>
    ));
    if (!this.state.model) {
      return (
        <section className="demo" id="demo">
          <div className="container">
            <div className="col-md-12">
              <div
                style={{ color: "#73fc68" }}
                className="spinner-border"
                role="status"
              ></div>
              <h1 className="pt-3">Assembling CNN Layers</h1>
              <img
                className="img-fluid"
                src={tensor_flow_loading}
                alt="preview"
              />
              <p></p>
            </div>
          </div>
        </section>
      );
    }
    return (
      <section className="demo" id="demo">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group files">
                <h1>Upload Your Cacao Images </h1>
                <br />
                <Dropzone accept="image/*" onDrop={this.onDrop.bind()}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div className="col-md-5">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p
                            className="p-3"
                            style={{
                              backgroundColor: "#efefef",
                              borderStyle: "dashed",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-outline-warning"
                            >
                              Browse
                            </button>
                            <br />
                            or drag photos here
                          </p>
                        </div>
                      </div>
                      <div className="container">
                        <button
                          type="button"
                          className="btn btn-success"
                          disabled={this.state.button_disable}
                          onClick={this.multiplePredictHandler}
                        >
                          Predict!
                        </button>
                        <br />
                        <div className={this.state.predict}>
                          <div
                            style={{ color: "#73fc68" }}
                            className="spinner-border"
                            role="status"
                          ></div>
                          <br />
                          <img
                            className="img-fluid"
                            src={predicting}
                            alt="preview"
                          />
                        </div>
                        <p
                          style={{ display: this.state.warning }}
                          className="mt-3"
                        >
                          {" "}
                          No images uploaded.
                        </p>
                        <button
                          style={{ display: this.state.warning }}
                          type="button"
                          className="mt-3 btn btn-light"
                          onClick={this.loadPhotos}
                        >
                          Try some sample photos!
                        </button>
                        <div className="row">{images}</div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <br />
              </div>
              <div className="form-group"></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default LoadModel;
