import React from "react";

class Paper extends React.Component {
  constructor(props) {
    window.location.replace(
      "https://myxarchive.s3.ap-southeast-1.amazonaws.com/pagaduan_thesis_manuscript.pdf"
    );
  }
}

export default Paper;
