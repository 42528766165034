import React from "react";
import mapboxgl from "mapbox-gl";

class MapSection extends React.Component {
  constructor(props) {
    require("dotenv").config();
    mapboxgl.accessToken = process.env.MAPBOX_KEY;
    super(props);
    this.state = {
      lng: 125.47562068492313,
      lat: 7.169911388769431,
      selectedFarm: null,
    };
    this.mapContainer = React.createRef();
  }
  componentDidMount() {
    const { lng, lat } = this.state;
    var farms = [
      {
        name: "Talomo River Farm",
        loc: [125.4727149, 7.1927815],
        img: "https://myxarchive.s3.ap-southeast-1.amazonaws.com/talomo.jpg",
        social: null,
        photos: 626,
      },
      {
        name: "Novela's Farm",
        loc: [125.38982872588585, 7.158353582587202],
        img: "https://myxarchive.s3.ap-southeast-1.amazonaws.com/novela.jpg",
        social: "https://www.facebook.com/novelasfarm/",
        photos: 4019,
      },
      {
        name: "KVT Farm Nursery",
        loc: [125.5296403, 7.085875499999999],
        img: "https://myxarchive.s3.ap-southeast-1.amazonaws.com/kvt.jpg",
        social: "https://www.facebook.com/KVTFarmAndNursery",
        photos: 335,
      },
    ];
    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: 9,
      maxBounds: [
        [117.17427453, 5.58100332277],
        [126.537423944, 18.5052273625],
      ],
    });
    map.on("load", () => {
      map.scrollZoom.disable();
      const nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "top-left");
      farms.forEach((farm) => {
        var marker = new mapboxgl.Marker({ color: "#73FC68" });
        var popUp = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setText(farm.name);
        popUp.on("open", () => {
          this.setState({ selectedFarm: farm });
        });
        popUp.on("close", () => {
          this.setState({ selectedFarm: null });
        });
        marker.setLngLat(farm.loc).setPopup(popUp).addTo(map);
      });
    });
  }
  render() {
    return (
      <div>
        <div ref={this.mapContainer} className="map-container" />
        {this.state.selectedFarm != null ? (
          <div className="details-container">
            <h3>{this.state.selectedFarm.name}</h3>
            {this.state.selectedFarm.social != null ? (
              <a href={this.state.selectedFarm.social} target="_blank">
                {this.state.selectedFarm.social}
              </a>
            ) : (
              <></>
            )}
            <p>data collected: {this.state.selectedFarm.photos} photos</p>
            <img
              src={this.state.selectedFarm.img}
              alt="preview"
              className="img-container"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default MapSection;
