import React from "react";
import title_image from "./assets/images/title_image.png";

class TitleSection extends React.Component {
  render() {
    return (
      <header className="page-header gradient">
        <div className="container pt-3">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <h1>Sikwati</h1>
              <p>Diagnose your cacaos today</p>
              <a
                type="button"
                className="btn btn-outline-success btn-lg"
                href="/#demo"
              >
                Try it now
              </a>
              <a
                type="button"
                className="btn btn-outline-warning btn-lg"
                href="https://www.youtube.com/watch?v=75TUC5Jav-U"
                target="_blank"
              >
                Learn More
              </a>
            </div>
            <div className="col-md-5">
              <img
                className="img-fluid"
                src={title_image}
                alt="Sikwati App"
              ></img>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default TitleSection;
